import axios from 'axios'
import PropTypes from 'prop-types'
import { useState, useCallback } from 'react'
import styled from 'styled-components'

import { Loader } from '@happymoneyinc/matter'

import { loggerClient } from '../../utils/loggerClient'

import { azureButonStyle } from '../GlobalStyle'

import ArticleCard from './ArticleCard'

let start = 6

const Articles = (props) => {
  const { articles, total } = props

  const [list, setList] = useState(articles)
  const [isLoading, setIsLoading] = useState(false)

  const loadMoreArticles = useCallback(async (e) => {
    e.preventDefault()

    setIsLoading(true)

    try {
      const { data } = await axios.post(`${ process.env.NEXT_PUBLIC_HM_POINT_BREAK_URL }/api/articles-data`, {
        start,
      })
      const parsedData = typeof (data) === 'string' ? JSON.parse(data) : data
      const updatedList = [...list, ...parsedData]
      setList(updatedList)
      setIsLoading(false)
      start = start + 6
    } catch (error) {
      loggerClient.error(`Error calling ${ process.env.NEXT_PUBLIC_HM_POINT_BREAK_URL }/api/articles-data`, {
        error,
      })
      setIsLoading(false)
    }
  }, [list])

  return (
    <>
      <ArticleList>
        {list.map((article, index) => (
          <ArticleCard article={article} key={`article-card-${ index.toString() }`} />
        ))}
      </ArticleList>

      {list.length < total && (
        <ViewMoreButton onClick={loadMoreArticles} data-testid="load-more-button" aria-label="view-more-articles">
          {isLoading ? <Loader colorTheme="light" size="sm" /> : 'View More'}
        </ViewMoreButton>
      )}
    </>
  )
}

const ArticleList = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 3rem;

  @media screen and (width >= 550px) {
    width: 100%;
    gap: 25px 25px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    gap: 4rem 4rem;
    grid-template-columns: repeat(3, 1fr);
  }
`

const ViewMoreButton = styled.button`
  ${ azureButonStyle }

  margin: 50px auto 0;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    margin: 100px auto 0;
  }

`

Articles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
}

export default Articles
