import PropTypes from 'prop-types'
import Head from 'next/head'

import styled from 'styled-components'

import { createClient } from '../../utils/prismic'
import { getPageData } from '../../utils/getPageData'
import { getPublishedDate } from '../../utils/date'

import ArticlesList from '../../components/articles/Articles'
import Hero from '../../components/articles/Hero'

import Container from '../../components/Container'
import Display from '../../components/Display'
import { REVALIDATE_TIME_INTERVAL } from '../../data/constants'

const Articles = (props) => {
  const { data, total } = props

  return (
    <>
      <Head>
        <title>Articles | Personal loans with your best interests at heart</title>
      </Head>

      <Hero>
        <StyledDisplay size="medium">Articles</StyledDisplay>
      </Hero>

      <Container>
        <ArticlesList articles={data} total={total} />
      </Container>
    </>
  )
}

const StyledDisplay = styled(Display)`
  margin: 0;
`

export async function getStaticProps({ preview = null, previewData = {} }) {
  const client = createClient({ previewData })

  if (preview) {
    const rawData = await client.getByType('blog', {
      field: 'document.first_publication_date',
      direction: 'desc',
    })

    const total = rawData.results.length
    const data = rawData.results.slice(0, 6)

    return {
      props: {
        preview,
        data,
        total,
      },
      revalidate: 1,
    }
  }

  const rawData = await getPageData({ type: 'blog' })
  const total = rawData.length
  const sortedData = rawData.sort(
    (a, b) => new Date(getPublishedDate(b)) - new Date(getPublishedDate(a)),
  )

  const data = sortedData.slice(0, 6)

  return {
    props: {
      data,
      total,
    },
    revalidate: REVALIDATE_TIME_INTERVAL,
  }
}

Articles.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
}

export default Articles
