import PropTypes from 'prop-types'
import styled from 'styled-components'

const Component = (props) => {
  const { children } = props

  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  padding: 100px 20px 120px;
  text-align: center;
  background-color: ${ ({ theme }) => theme.colors.cream };
`

Component.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Component
