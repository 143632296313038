import { memo } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { formatDate } from '../../utils/date'

import Headline from '../Headline'
import Text from '../Text'

const ArticleCard = memo((props) => {
  const { theme } = props

  const backgroundColorDefaults = [
    theme.colors.flamingo[3],
    theme.colors.poppy[3],
    theme.colors.fire[3],
    theme.colors.azure[3],
    theme.colors.chartreuse[3],
    theme.colors.ultraviolet[3],
    theme.colors.lemon[3],
  ]

  const {
    article: {
      first_publication_date,
      uid: id,
      data: {
        bg_color,
        image: {
          url,

          alt: altText,
        },
        publish_date,
        title,
      },
    },
  } = props

  const getColor = (color) => {
    if (color && color.length > 7) {
      return color.substring(0, 7)
    }
    return color
  }

  return (
    <Wrapper data-testid="articles-article-item">
      <Link href={`/articles/${ id }`} passHref>
        <Anchor data-testid={'link-read-more'}>
          <ImageWrapper backgroundColor={getColor(bg_color) || backgroundColorDefaults[Math.floor(Math.random() * backgroundColorDefaults.length)]}>
            <Image objectFit="fill" src={url} alt={altText} width={450} height={290} />
          </ImageWrapper>

          <Title size="medium">{title[0].text}</Title>
          <StyledText>{formatDate(publish_date) || formatDate(first_publication_date)}</StyledText>
        </Anchor>
      </Link>
    </Wrapper>
  )
})

const StyledText = styled(Text)`
  margin-top: 18px;
  margin-bottom: 0;
  color: ${ ({ theme }) => theme.colors.gray[1] };
  font-weight: 400;
  line-height: 150%;
`

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: calc(100% - 11px);
    content: '';
    background-color: ${ ({ backgroundColor }) => backgroundColor } !important;
    border-radius: 10px;
    mix-blend-mode: hard-light;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: calc(100% - 11px);
    content: '';
    background-color: #e7e6e4;
    border-radius: 10px;
    mix-blend-mode: saturation;
  }

  img {
    border-radius: 10px;
  }
`

const Wrapper = styled.div`
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  display: flex;
  width: 100%;
  transform: scale(1);
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
        transform: scale(1.02);
    }
`

const Title = styled(Headline)`
  margin-bottom: 0;
`

const Anchor = styled.a`
  ::after {
    content: none;
  }
`

ArticleCard.defaultProps = {
  article: {
    data: {
      background_color: null,
      publish_date: null,
      image: {
        alt: '',
      },
    },
  },
}

ArticleCard.propTypes = {
  article: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    first_publication_date: PropTypes.string.isRequired,
    data: PropTypes.shape({
      bg_color: PropTypes.string,
      title: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })).isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        }),
      }),
      publish_date: PropTypes.string,
    }),
  }),
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default withTheme(ArticleCard)
